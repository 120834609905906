* {
  margin: 0;
  padding: 0;
  font-family: "roboto", "sans-serif";
  box-sizing: border-box;
}

.container {
  width: 100vw;
  height: 100vh;
  /* background-image: url(./../images/background.png);
  background-position: center;
  background-size: cover; */
  padding: 0 8%;
}

.content {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  color: #fff;
}

.content h1 {
  font-size: 64px;
  font-weight: 600;
}

.content h1 span {
  color: #ff3753;
}

.content button {
  background: transparent;
  border: 2px solid #fff;
  outline: none;
  padding: 12px 25px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
}

.content button img {
  width: 15px;
  margin-left: 10px;
}

.launch-time {
  display: flex;
}

.launch-time div {
  flex-basis: 100px;
}

.launch-time div p {
  font-size: 60px;
  margin-bottom: -14px;
}

.rocket {
  width: 250px;
  position: absolute;
  right: 10%;
  bottom: 0;
  animation: rocket 4s linear infinite;
}

@keyframes rocket {
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    bottom: 105%;
    opacity: 1;
  }
}
