* {
  color: white;
}
body {
  margin: 0;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/static/media/bg.c1f7aa40.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.client-main-div div {
    -webkit-animation: slide-in 1s ease-in-out 0s 1 forwards;
            animation: slide-in 1s ease-in-out 0s 1 forwards;
    justify-content: center;
}

h2 {
    /* font-family: 'Roboto', sans-serif; */
    font-size: 48px;
    text-shadow: 12px 5px 5px rgb(0 0 0 / 50%);
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-transform: uppercase;
    font-style: italic;
}

h5{
    
    text-shadow: 12px 5px 5px rgb(0 0 0 / 50%);
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 700;
}

.client-inner-div{
    font-size: 18px;
    font-style: italic;
    font-weight: bold;
    color: black;
    background-color: #bdd2f4;
}



.client-upgrade-package button:hover{
    border:2px solid #002D74;    
}

.upgrade-package-submit-button{
    
    box-shadow: inset black 0px 0px 3px 0px;
    color:white;
    font-weight: 600;
    background-color: #002D74;
    padding-left: 2rem;
    padding-right: 2rem;

}
@-webkit-keyframes slide-in {
    from {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    to {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@keyframes slide-in {
    from {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    to {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
.client-upgrade-package {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.client-upgrade-package button {
    border: 2px solid;
    background-color: #bdd2f4;
    box-shadow: black 12px 14px 20px;
    flex: 1 1;
    margin: 5px;
}

@media (max-width: 768px) {
    .client-upgrade-package button {
        font-size: 14px;
        padding: 10px 15px;
        margin: 5px;
    }

    h2 {
        font-size: 28px;

    }
}
.test{
    font-size: 12px;
}
.admin-main-div {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
}
* {
  color: "black";
}
.logo123 {
  /* border: 1px solid black; */
  width: 70px;
}
@media (max-width: 768px) {
  .logo123 {
    width: 140px;
  }

}
* {
  margin: 0;
  padding: 0;
  font-family: "roboto", "sans-serif";
  box-sizing: border-box;
}

.container {
  width: 100vw;
  height: 100vh;
  /* background-image: url(./../images/background.png);
  background-position: center;
  background-size: cover; */
  padding: 0 8%;
}

.content {
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #fff;
}

.content h1 {
  font-size: 64px;
  font-weight: 600;
}

.content h1 span {
  color: #ff3753;
}

.content button {
  background: transparent;
  border: 2px solid #fff;
  outline: none;
  padding: 12px 25px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
}

.content button img {
  width: 15px;
  margin-left: 10px;
}

.launch-time {
  display: flex;
}

.launch-time div {
  flex-basis: 100px;
}

.launch-time div p {
  font-size: 60px;
  margin-bottom: -14px;
}

.rocket {
  width: 250px;
  position: absolute;
  right: 10%;
  bottom: 0;
  -webkit-animation: rocket 4s linear infinite;
          animation: rocket 4s linear infinite;
}

@-webkit-keyframes rocket {
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    bottom: 105%;
    opacity: 1;
  }
}

@keyframes rocket {
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    bottom: 105%;
    opacity: 1;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: "roboto", "sans-serif";
  box-sizing: border-box;
}

.container {
  width: 100vw;
  height: 100vh;
  /* background-image: url(./../images/background.png);
  background-position: center;
  background-size: cover; */
  padding: 0 8%;
}

.social-content {
  /* top: 50%; */
  /* position: absolute; */
  /* transform: translateY(50%); */
  margin-top: 5%;
  margin-bottom: 5%;
  color: #fff;
}

.social-content h1 {
  margin-top: 50px;
  font-size: 64px;
  font-weight: 600;
}

.social-content h1 span {
  color: #ff3753;
}

.social-media-icons-white {
  display: flex;
  justify-content: space-evenly;
  justify-items: center;
  align-items: center;
}

.social-media-icons-white a {
  color: white;
  font-size: 6rem;
}

.timer {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #333;
  }
  
  .launching {
    -webkit-animation: launching 1s ease-in-out;
            animation: launching 1s ease-in-out;
  }
  
  @-webkit-keyframes launching {
    from {
      opacity: 0;
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  
  @keyframes launching {
    from {
      opacity: 0;
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  
  .time-left {
    margin-bottom: 10px;
  }
  
  .countdown {
    display: inline-block;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    -webkit-animation: countdown 1s ease-in-out;
            animation: countdown 1s ease-in-out;
  }
  
  @-webkit-keyframes countdown {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes countdown {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  .seconds {
    margin-left: 10px;
  }
  
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(/static/media/background.7ad08359.png);
  background-position: center;
  background-size: cover;
  padding: 0 8%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .launch-page {
  display: flex;
  flex-direction: column;
  align-items: center;
    justify-content: center;
    height: 100vh;
}

.description {
  margin-top: 80px;
  padding: 0 20px;
  text-align: center;
}
  
    .description h1 {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 20px;
    color: white;
    text-transform: uppercase;
      letter-spacing: 2px;
}
  
    .description p {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 20px;
      color: white;
    }
    .social-icons a:not(:last-child) {
      margin-right: 20px;
    }

    .social-icons {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  
    .social-icons a {
      margin: 0 10px;
    }
  
    @media (max-width: 768px) {
      .launch-page {
        height: 150vh;
      }
  
      .description {
        padding: 0 10px;
      }
  
      .social-icons {
        flex-wrap: wrap;
      }
  
      .social-icons a {
        margin: 10px 0;
      }
    }
     */
