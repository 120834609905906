.timer {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #333;
  }
  
  .launching {
    animation: launching 1s ease-in-out;
  }
  
  @keyframes launching {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .time-left {
    margin-bottom: 10px;
  }
  
  .countdown {
    display: inline-block;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    animation: countdown 1s ease-in-out;
  }
  
  @keyframes countdown {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  
  .seconds {
    margin-left: 10px;
  }
  